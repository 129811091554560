import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WhiteLayout from '@l/WhiteLayout';

// Actions
import { changeCurrentPage } from '@s/store/routing/actions';
import { changeAppData} from '@s/store/app-data/actions';
  

const YouYears = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const appData = useSelector((state) => state.appData).toJS();
  const {
    startData: { acf },
  } = startData;

  const { youyears_heading, youyears_image, youyears_description, youyears_button_name_yes , youyears_button_name_no} = acf;

  const handleOnClickButtonNo = () => dispatch(changeCurrentPage('you-years-sorry'));
  const handleOnClickButtonYes = () => {
    const newAppData = {
      ...appData,
      is_years: true
    }
    dispatch(changeAppData(newAppData));
    dispatch(changeCurrentPage('agb'));
  }

  return (
    <WhiteLayout
      heading={youyears_heading && <h1>{youyears_heading}</h1>}
      image={youyears_image && <img src={youyears_image.url} alt={youyears_image.alt} />}
      description={youyears_description}
      buttons={
        <>
          {youyears_button_name_no && <button onClick={() => handleOnClickButtonNo()} type='button' className="app-button app-button--outline">{youyears_button_name_no}</button>}
          {youyears_button_name_yes && <button onClick={() => handleOnClickButtonYes()} type='button' className="app-button">{youyears_button_name_yes}</button>}
        </>
      }
    />
  );
};

export default YouYears;
