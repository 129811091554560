import React from 'react';

import s from './styles.module.scss';

const PictureSection = ({ data }) => {
  const { picture, height, frame } = data;
  const style = {
    height: height + '%',
  };

  const styleImg = {};

  // if (frame === 'yes') {
  //   style.border = '5px solid var(--yellow)';
  // }

  return (
    <div style={style} className={s['picture-section']}>
      {picture && (
        <img
          style={styleImg}
          className={s['picture-section__picture']}
          src={picture.sizes.medium_large}
          alt={picture.alt}
        />
      )}
    </div>
  );
};

export default PictureSection;
