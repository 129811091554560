// Core
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import App from '@/App';

// Pages
// import LetsGo from '@p/LetsGo';
// import Chapters from '@p/Chapters';
// import ChapterItem from '@p/ChapterItem';
// import StepItem from '@p/StepItem';
// import Page1 from '@p/Page1';
// import ErrorPage from '@p/ErrorPage';

export const Routes = () => {
  return (
    <Router>
     <Switch>
        {/* 
        <Route exact component={Chapters} path={book.chapters} />
        <Route exact component={ChapterItem} path={`${book.chapters}/:slug`} />
        <Route exact component={StepItem} path={`${book.chapters}/:slug/:step`} />
        <Route exact component={Page1} path={book.page1} />
        <Route component={ErrorPage} /> */}
        <Route exact component={App} path='/' />
        <Redirect to='/' />
      </Switch>
    </Router>
  );
};
