import React from 'react';

//Components
import NextScreenButton from '@c/Navigation/NextScreenButton';

import s from './styles.module.scss';

const ButtonSection = ({ data }) => {
  const { button_text } = data;

  const style = {
    height: data.height + '%',
  };

  return (
    <div style={style} className={`${s['button']} app-container`}>
      {button_text && (
        <NextScreenButton>
          <button type='button' className='app-button'>
            {button_text}
          </button>
        </NextScreenButton>
      )}
    </div>
  );
};

export default ButtonSection;
