// Types
import {FETCH_CHAPTER_ASYNC, FILL_CHAPTER, CLEAR_CHAPTER } from './types';

// Instruments
import { api } from '@/rest';

export const fillChapter = (chapter) => {
  return {
    type: FILL_CHAPTER,
    payload: chapter,
  };
};

export const clearChapter = () => {
  return {
    type: CLEAR_CHAPTER,
  };
};

export const fetchChapterAsync = (chapterID) => async (dispatch) => {

  dispatch({
    type: FETCH_CHAPTER_ASYNC,
  });

  const {data} = await api.chapters.fetchItem(chapterID);

  dispatch(fillChapter(data));
};

