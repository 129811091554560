import React from 'react';

// Images
import { ReactComponent as CloseButton } from '@img/CloseIcon.svg';

// Styles
import s from './styles.module.scss';

const TopModal = ({title, description, buttons, closeModalFunc, isShowModal}) => {

  if(!isShowModal){
    return false
  }
  
  return (
    <div className={s['top-modal']}>
      <div onClick={closeModalFunc} className={s['top-modal__background']}></div>
      <div className={s['top-modal__modal']}>
        <CloseButton onClick={closeModalFunc} className={s['top-modal__close']}/>
        <div className={s['top-modal__content']}>
          {title &&<h2 className={`${s['top-modal__title']} h2`}>{title}</h2>}
          {description && <div className={s['top-modal__description']} dangerouslySetInnerHTML={{ __html: description }}/>}
          {buttons && <div className={s['top-modal__buttons']}>{buttons}</div>}
        </div>
      </div>
    </div>
  );
};

export default TopModal;
