import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Instruments
import { getCookie } from '@u/cookie';
// Components
import ProgressLoader from '@c/ProgressLoader';

// Actions
import { changeCurrentPage } from '@s/store/routing/actions';
import { changeAppData } from '@s/store/app-data/actions';
import { changeAppDataSession } from '@s/store/app-data-session/actions';

// Images
import { ReactComponent as ZapfenImage } from '@img/Zapfen2.svg';
// Styles

import s from './styles.module.scss';

const FirstLoading = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const appData = useSelector((state) => state.appData).toJS();
  const appDataSession = useSelector((state) => state.appDataSession).toJS();
  const {
    startData: { acf },
  } = startData;

  const [percentage, setPercentage] = useState(0);

  // const percents = [ 10, 20];
  // const randomIndex = Math.floor(Math.random() * 2);
  const percents = [1, 2];
  const randomIndex = Math.floor(Math.random() * 2);

  function goToRout(cookie) {
    if (cookie.is_agb) {
      dispatch(changeCurrentPage('main-menu'));
    } else if (cookie.is_years) {
      dispatch(changeCurrentPage('agb'));
    } else {
      dispatch(changeCurrentPage('welcome'));
    }
  }

  useEffect(() => {
    if (!getCookie('appDataSession')) {
      let chagePercentage = setTimeout(() => {
        if (percentage <= 99) {
          if (percentage + percents[randomIndex] >= 100) {
            setPercentage(100);
          } else {
            setPercentage(percentage + percents[randomIndex]);
          }
        } else {
          if (acf !== undefined) {
            if (!getCookie('appDataSession')) {
              const newAppDataSession = {
                ...appDataSession,
                firstLoading: true,
              };
              dispatch(changeAppDataSession(newAppDataSession));
            }

            if (!getCookie('appData')) {
              const newAppData = {
                ...appData,
                userId: new Date().getTime(),
              };
              dispatch(changeAppData(newAppData));
            }

            goToRout(JSON.parse(getCookie('appData')));
          }
        }
      }, 10);

      return () => {
        clearTimeout(chagePercentage);
      };
    } else {
      if (acf) {
        dispatch(changeAppDataSession(JSON.parse(getCookie('appDataSession'))));
        dispatch(changeAppData(JSON.parse(getCookie('appData'))));

        goToRout(JSON.parse(getCookie('appData')));
      }
    }
  }, [percentage, acf]);

  if (getCookie('appDataSession')) {
    return false;
  }

  return (
    <div className={s['first-loading']}>
      <div className={s['first-loading__header']}>
        <ZapfenImage />
      </div>
      <ProgressLoader percentage={percentage} />
      <div className={s['first-loading__footer']}></div>
    </div>
  );
};

export default FirstLoading;
