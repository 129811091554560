import React from 'react';
import { useSelector } from 'react-redux';

import Game from '@c/Game';
import PictureSection from '@c/PictureSection';
import HeadingDescription from '@c/HeadingDescription';
import TextWidthButtonSection from '@c/TextWidthButtonSection';
import VideoPlayer from '@c/VideoPlayer';
import AudioPlayer from '@c/AudioPlayer';
import TextFullScreen from '@c/TextFullScreen';
import ImagePoints from '@c/ImagePoints';
import LinkPopup from '@c/LinkPopup';
import ButtonSection from '@c/ButtonSection';
import TalkingHead from '@c/TalkingHead';
import ResultsScreen from '@c/ResultsScreen';
import SubmitForm from '@c/SubmitForm';
import BirgitMessage from '@c/BirgitMessage';
import SpeechBubble from '@c/SpeechBubble';

import s from './styles.module.scss';

const Sections = ({ sections }) => {
  return sections.map((section, i) => {
    const { unique_id, acf_fc_layout } = section;

    const style = {
      height: section.height + '%',
    };

    switch (acf_fc_layout) {
      case 'game':
        return (
          <div style={style} className={s['game-wrapper']} key={unique_id}>
            <Game data={section} />
          </div>
        );

      case 'picture':
        return <PictureSection key={unique_id} data={section} />;

      case 'heading_description':
        return <HeadingDescription key={unique_id} data={section} />;

      case 'text_with_button':
        return <TextWidthButtonSection key={unique_id} data={section} />;

      case 'video':
        return <VideoPlayer key={unique_id} data={section} />;

      case 'audio':
        return <AudioPlayer key={unique_id} data={section} />;

      case 'link_popup':
        return <LinkPopup key={unique_id} data={section} />;

      case 'text_full_screen':
        return <TextFullScreen key={unique_id} data={section} />;

      case 'image_points':
        return <ImagePoints key={unique_id} data={section} />;

      case 'button':
        return <ButtonSection key={unique_id} data={section} />;

      case 'talking_head':
        return <TalkingHead key={unique_id} data={section} />;

      case 'chapter_results':
        return <ResultsScreen key={unique_id} data={section} />;

      case 'submit_form':
        return <SubmitForm key={unique_id} data={section} />;

      case 'birgit_message':
        return <BirgitMessage key={unique_id} data={section} />;

      case 'speech_bubble':
        return <SpeechBubble key={unique_id} data={section} />;

      default:
        return <h1 key={i}>Not found section "{acf_fc_layout}"!</h1>;
    }
  });
};

export default Sections;
