import React, { useState } from 'react';

// import GameHeader from '../GameHeader';
import ImageModal from '../ImageModal';

import s from './styles.module.scss';

const ImagePoints = ({ data }) => {
  const [showModal, setShowModal] = useState(false);

  const { heading, description, image_for_points, points, image_full_size } = data;

  const handleCloseModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={s['image-points']}>
      {/* <GameHeader heading={heading} description={description} /> */}

      <div className={s['image-points__container']}>
        <div className={s['image-points__image']}>
          {image_for_points && (
            <img
              onClick={() => setShowModal(!showModal)}
              src={image_for_points.sizes.medium_large}
              alt={image_for_points.alt}
            />
          )}
          {points &&
            points.map(({ point }, i) => {
              const coordinates = point.split(',');
              const styleCords = {
                left: coordinates[0],
                top: coordinates[1],
              };
              return (
                <button
                  className={s['image-points__point']}
                  type='button'
                  key={i}
                  style={styleCords}>
                  {i + 1}
                </button>
              );
            })}
        </div>
      </div>
      <ImageModal
        image={image_full_size.sizes.medium_large}
        alt={image_full_size.alt}
        isShow={showModal}
        closeFunc={handleCloseModal}
      />
    </div>
  );
};

export default ImagePoints;
