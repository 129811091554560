// export function secondsToHms(d) {
//     d = Number(d);
//     var h = Math.floor(d / 3600);
//     var m = Math.floor(d % 3600 / 60);
//     var s = Math.floor(d % 3600 % 60);

//     var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
//     var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
//     var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
//     return hDisplay + mDisplay + sDisplay; 
// }

export function secondsToHms(d) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const hDisplay = h > 0 ? h + " hr, " : "";
  const mDisplay = m > 0 ? m + " min" : "";
  return hDisplay + mDisplay; 
}


export function metersToKilometers(m) {
  m = Number(m);
  const km = (m/1000).toFixed(2);
  const kmDisplay = km + ' km';
  return kmDisplay; 
}