import { fromJS, Map } from 'immutable';

import { FILL_CHAPTER, CLEAR_CHAPTER } from './types';

const initialState = Map();

export const chapterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILL_CHAPTER:
      return fromJS(action.payload);

    case CLEAR_CHAPTER:
      return initialState;

    default:
      return state;
  }
};
