import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import WhiteLayout from '@l/WhiteLayout';
import Checkbox from '@c/Checkbox';

// Actions
import { changeCurrentPage } from '@s/store/routing/actions';
import { changeAppData} from '@s/store/app-data/actions';

const Agb = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const appData = useSelector((state) => state.appData).toJS();
  const [disabled, setDisabled] = useState(true);
  const {
    startData: { acf },
  } = startData;

  const { agb_heading, agb_description, agb_link_text, agb_checkbox_text, agb_button_name } = acf;

  const handleOnClickButton = () => {
    const newAppData = {
      ...appData,
      is_agb: true
    }
    dispatch(changeAppData(newAppData));
    dispatch(changeCurrentPage('main-menu'))
  };

  const handleOnChangeCheckbox = () => {
    setDisabled(!disabled);
  };

  const handleClickLink = () => {
    dispatch(changeCurrentPage('privacy-policy'))
  };


  return (
    <WhiteLayout
      heading={agb_heading && <h1>{agb_heading}</h1>}
      description={agb_description}
      link={agb_link_text && <button onClick={handleClickLink} type='button'>{agb_link_text}</button>}
      checkbox={agb_checkbox_text && <Checkbox onChangeFunc={handleOnChangeCheckbox} label={agb_checkbox_text} id='agb_accept' />}
      buttons={agb_button_name && <button disabled={disabled} onClick={() => handleOnClickButton()} type='button' className="app-button">{agb_button_name}</button>}
    />
  );
};

export default Agb;