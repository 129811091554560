import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as ZapfenImage } from '@img/ZapfenBig.svg';

import fadeTransition from "./fade.module.scss";
import s from './styles.module.scss';

const ImageModal = ({image, alt, isShow, closeFunc}) => {
  const nodeRef = React.useRef(null);

  return (
    <CSSTransition 
      nodeRef={nodeRef}
      in={isShow} 
      timeout={300} 
      classNames={fadeTransition} 
      unmountOnExit 
      // onEnter={() => setShowButton(false)} 
      // onExited={() => setShowButton(true)}
    >
      <div ref={nodeRef} className={s['image-modal']} >
        <ZapfenImage className={s['image-modal__zapfen']} />
        <img src={image} alt={alt} />
        <div className={s['image-modal__label']} onClick={closeFunc}>Fullscreen schließen</div>
      </div>
    </CSSTransition>
  );
};

export default ImageModal;