import React from 'react';

import s from './styles.module.scss';

import { ReactComponent as ZapfenImage } from '@img/ZapfenBig.svg';

const MainLayout = ({ children }) => {
  return (
    <div className={s['main-layout']}>
      <ZapfenImage className={s['main-layout__zapfen']} />
      <div className={s['main-layout__content']}>{children}</div>
    </div>
  );
};

export default MainLayout;
