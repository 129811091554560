import React from 'react';

import Sections from '../Sections';

import { ReactComponent as ZapfenImageBig } from '@img/ZapfenBig.svg';

import s from './styles.module.scss';
import './styles.scss';

const StepScreens = ({ screen, screen_styles }) => {
  if (!screen) {
    return false;
  }

  let styles = {};

  if (screen_styles.blocks_alignment) {
    switch (screen_styles.blocks_alignment.value) {
      case 'top':
        styles.justifyContent = 'flex-start';
        break;
      case 'center':
        styles.justifyContent = 'center';
        break;

      case 'justify':
        styles.justifyContent = 'space-between';
        break;

      default:
        styles.justifyContent = 'flex-start';
        break;
    }
  }

  return (
    <div className={`${s['chapter-steps']} ${'chapter-steps--' + screen_styles.screen_style}`}>
      {screen_styles.screen_style === 'style2' && <ZapfenImageBig className='big-zapfen' />}
      <div style={styles} className={s['chapter-steps__item']}>
        <Sections sections={screen} />
      </div>
    </div>
  );
};

export default StepScreens;
