import React, { useEffect } from 'react';

//Components
import NextScreenButton from '@c/Navigation/NextScreenButton';

import { ReactComponent as ZapfenImage } from '@img/Zapfen2.svg';

import s from './styles.module.scss';

const TextWidthButtonSection = ({ data }) => {
  const { text, button_name } = data;

  return (
    <div className={s['text-with-button']}>
      <ZapfenImage className={s['text-with-button__zapfen']} />
      {text && (
        <div className={s['text-with-button__text']} dangerouslySetInnerHTML={{ __html: text }} />
      )}
      {button_name && (
        <NextScreenButton>
          <div className={`${s['text-with-button__button']} app-button`}>{button_name}</div>
        </NextScreenButton>
      )}
    </div>
  );
};

export default TextWidthButtonSection;
