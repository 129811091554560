import React from 'react';

import s from './styles.module.scss';

import { ReactComponent as CheckIcon } from '@img/Check.svg';

const Checkbox = ({ label, id, onChangeFunc }) => {
  return (
    <div className={s['checkbox']}>
      <input onChange={() => onChangeFunc()} type='checkbox' id={id} />
      <label htmlFor={id}>
        <span>
          <CheckIcon />
        </span>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
