import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@c/Loading';
import Steps from '@c/Steps';
import MainLayout from '@l/MainLayout';

import { fetchChapterAsync } from '@s/store/chapter/actions';
import { changeCurrentStep } from '@s/store/routing/actions';

const ChapterItem = () => {
  const dispatch = useDispatch();
  const chapter = useSelector((state) => state.chapter).toJS();
  const { chapterID } = useSelector((state) => state.routing).toJS();
  const { currentChapterId, currentStep, completedChapters } = useSelector(
    (state) => state.appData
  ).toJS();

  const chapterIDForGettingData = chapterID ? chapterID : currentChapterId;

  useEffect(() => {
    chapterIDForGettingData && dispatch(fetchChapterAsync(chapterIDForGettingData));

    dispatch(changeCurrentStep(currentStep));
  }, [chapterIDForGettingData]);

  // if (!Object.keys(chapter).length) {
  //   return false;
  // }

  const isPassedChapter = completedChapters.indexOf(chapterIDForGettingData) !== -1;

  if (!chapter.acf) {
    return <Loading />;
  }

  return (
    <MainLayout>
      <Steps
        isPassedChapter={isPassedChapter}
        currentStep={currentStep}
        steps={chapter.acf.steps}
        chapter={chapterID}
      />
    </MainLayout>
  );
};

export default ChapterItem;
