import { Map, fromJS } from 'immutable';

import { FILL_START_DATA } from './types';

const initialState = Map({
  startData: Map({}),
});

export const startDataReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case FILL_START_DATA:
      return state.set('startData', fromJS(payload));

    default:
      return state;
  }
};


