import React from 'react';

// Images
import { ReactComponent as CloseButton } from '@img/CloseIcon.svg';
import { ReactComponent as ModalLine } from '@img/ModalLine.svg';
import { ReactComponent as ZapfenImage } from '@img/Zapfen.svg';

// Styles
import s from './styles.module.scss';

const CenterModal = ({ children, closeModalFunc, isShowModal }) => {
  if (!isShowModal) {
    return false;
  }

  return (
    <div className={s['center-modal']}>
      <div onClick={closeModalFunc} className={s['center-modal__background']}></div>
      <div className={s['center-modal__modal']}>
        <ZapfenImage className={s['center-modal__zapfen']} />
        <ModalLine className={s['center-modal__line']} />
        {closeModalFunc && (
          <CloseButton onClick={closeModalFunc} className={s['center-modal__close']} />
        )}
        <div className={s['center-modal__content']}>{children}</div>
      </div>
    </div>
  );
};

export default CenterModal;
