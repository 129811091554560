import { fromJS, Map } from 'immutable';

import { FILL_GAME, CLEAR_GAME } from './types';

const initialState = Map();

export const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILL_GAME:
      return fromJS(action.payload);
    case CLEAR_GAME:
      return initialState;
    default:
      return state;
  }
};
