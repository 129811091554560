// Types
import { FILL_START_DATA, FETCH_START_DATA } from './types';

// Instruments
import { api } from '@/rest';

export const fillStartData = (data) => {
  return {
    type: FILL_START_DATA,
    payload: data,
  };
};

export const fetchStartDataAsync = () => async (dispatch) => {

  dispatch({
    type: FETCH_START_DATA,
  });

  const {data} = await api.startData.fetch();

  dispatch(fillStartData(data));
};

