import React from 'react';

import ProgressLoader from '@c/ProgressLoader';

import s from './styles.module.scss';

const Loading = ({ opacity }) => {
  const [percentage, setPercentage] = React.useState(0);
  // const percents = [ 10, 20];
  // const randomIndex = Math.floor(Math.random() * 2);
  const style = { backgroundColor: `rgba(255,255,255, ${opacity})` };

  React.useEffect(() => {
    let chagePercentage = setTimeout(() => {
      if (percentage <= 94) {
        setPercentage(percentage + 1);
      }
    }, 0.5);

    return () => {
      clearTimeout(chagePercentage);
    };
  }, [percentage]);

  return (
    <div style={style} className={s['loading']}>
      <ProgressLoader percentage={percentage} />
    </div>
  );
};

Loading.defaultProps = {
  opacity: 0.8,
};

export default Loading;
