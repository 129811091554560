import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveGameResults, saveAllPoints, saveRightPoints } from '@s/store/app-data/actions';
import { addQuizAnimation } from '@s/store/animations/actions';

// import GameHeader from '../GameHeader';
import BottomModal from '../BottomModal';

import s from './styles.module.scss';

import { ReactComponent as LightBulbImage } from '@img/LightBulb.svg';

const Quiz = ({ id, data, points }) => {
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const appData = useSelector((state) => state.appData).toJS();
  const animations = useSelector((state) => state.animations).toJS();

  const listPoints = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];

  const handleClickCheckbox = (idAnswer) => {
    // if (answers.includes(idAnswer)) {
    //   setAnswers(answers.filter((answer) => answer !== idAnswer));
    // } else {
    //   setAnswers([...answers, idAnswer]);
    // }
    setAnswers([idAnswer]);
  };

  useEffect(() => {
    const quiz = appData.gamesResults[id];
    let isRight = false;

    if (quiz) {
      function intersect(arr1, arr2) {
        return arr1.filter(function (n) {
          return arr2.indexOf(n.unique_id) !== -1;
        });
      }

      const rightAnswers = data.answers.filter((anwer) => anwer.right_answer === true);
      const intersectArr = intersect(rightAnswers, answers);

      // if (intersectArr.length === answers.length) {
      //   isRight = true;
      // }
      if (intersectArr.length) {
        isRight = true;
      }
    }

    const rPoints = isRight ? points : 0;

    const answersObj = {
      [id]: {
        type: 'Quiz',
        isAnswer: false,
        answersIds: answers,
        isRight,
        allPoints: points,
        rightPoints: rPoints,
      },
    };

    if (!quiz || !quiz.isAnswer) {
      dispatch(saveGameResults(answersObj));
      dispatch(saveRightPoints({ gameId: id, points: rPoints }));
    }
  }, [answers]);

  useEffect(() => {
    dispatch(saveAllPoints({ gameId: id, points }));
    return () => {
      dispatch(addQuizAnimation(false));
    };
  }, []);

  useEffect(() => {
    if (animations.quiz) {
      setAnimationClass(s['game-quiz__answers-list--animation']);
    }
  }, [animations]);

  const handleClickNote = () => {
    setShowModal(true);
  };

  const handleClickCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={s['game-quiz']}>
      {/* <GameHeader heading={data.question} description={data.description} /> */}

      <div className={s['game-quiz__answers']}>
        <div className='app-container'>
          {data.show_popup !== 'no' && (
            <h2 onClick={() => handleClickNote()} className={`${s['game-quiz__hinweis']} h3`}>
              <LightBulbImage />
              <span>Hinweis</span>
            </h2>
          )}

          <BottomModal closeModalFunc={handleClickCloseModal} isShowModal={showModal}>
            {data.popup_heading && <h1 className='h2'>{data.popup_heading}</h1>}
            {data.popup_description && (
              <div dangerouslySetInnerHTML={{ __html: data.popup_description }}></div>
            )}
          </BottomModal>

          <div className={`${s['game-quiz__answers-list']} ${animationClass}`}>
            {data.answers.map(({ unique_id, text, right_answer }, i) => {
              const quiz = appData.gamesResults[id];

              let classCheckbox = '';
              let disabled = false;
              if (typeof quiz !== 'undefined' && quiz.isAnswer) {
                disabled = true;

                if (right_answer) {
                  classCheckbox = s['game-quiz__checkbox--right'];
                } else if (!right_answer && quiz.answersIds.includes(unique_id)) {
                  classCheckbox = s['game-quiz__checkbox--wrong'];
                }
              }

              return (
                <div key={unique_id} className={`${s['game-quiz__checkbox']} ${classCheckbox}`}>
                  <input
                    onChange={() => {
                      handleClickCheckbox(unique_id);
                    }}
                    disabled={disabled}
                    id={unique_id}
                    type='radio'
                    name={id}
                  />
                  <label htmlFor={unique_id}>
                    <div className={s['game-quiz__label-content']}>
                      <div className={s['game-quiz__answers-point']}>{listPoints[i]}.</div>
                      {text}
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
