import { Map, List, fromJS } from 'immutable';

import { SUBMIT_FORM_SUCCEEDED, SUBMIT_FORM_REQUESTED, SUBMIT_FORM_FAILED } from './types';

const initialState = Map({
  isLoading: false,
  errors: List(),
  message: '',
  successful: false,
});

export const submitFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBMIT_FORM_REQUESTED:
      return fromJS({ isLoading: true, errors: [], message: '', successful: false });
    case SUBMIT_FORM_SUCCEEDED:
      return fromJS({ isLoading: false, message: payload.message, errors: [], successful: true });
    case SUBMIT_FORM_FAILED:
      return fromJS({
        isLoading: false,
        errors: payload.errors,
        message: payload.message,
        successful: false,
      });
    default:
      return state;
  }
};
