import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Loading from '@c/Loading';
import ChapterCards from '@c/ChapterCards';
import MainLayout from '@l/MainLayout';

import { changeAppData } from '@s/store/app-data/actions';
import { changeCurrentChapter } from '@s/store/routing/actions';
import { clearChapter } from '@s/store/chapter/actions';

const Chapters = () => {
  const dispatch = useDispatch();
  const chapters = useSelector((state) => state.chapters).toJS();
  const appData = useSelector((state) => state.appData).toJS();
  const { currentChapter, completedChapters, currentChapterId } = appData;

  useEffect(() => {
    if (chapters[currentChapter - 1]) {
      const newAppData = {
        ...appData,
        currentChapterId: chapters[currentChapter - 1].id,
      };
      dispatch(changeAppData(newAppData));
      dispatch(clearChapter());

      if (currentChapterId) {
        dispatch(changeCurrentChapter(chapters[currentChapter - 1].id));
      } else {
        dispatch(changeCurrentChapter(chapters[currentChapter - 1].id));
      }
    }
  }, [currentChapter]);

  if (!chapters.length) {
    return <Loading />;
  }

  return (
    <MainLayout>
      <ChapterCards
        chapters={chapters}
        completedChapters={completedChapters}
        currentChapterId={currentChapterId}
      />
    </MainLayout>
  );
};

export default Chapters;
