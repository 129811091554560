// Types
import { SUBMIT_FORM_SUCCEEDED, SUBMIT_FORM_REQUESTED, SUBMIT_FORM_FAILED } from './types';

// Instruments
import { api } from '@/rest';

export const submitFormAsync = (data) => async (dispatch) => {
  dispatch({
    type: SUBMIT_FORM_REQUESTED,
  });

  try {
    const response = await api.submitForm.fetch(data);

    if (response.data.status === 'mail_sent') {
      dispatch({
        type: SUBMIT_FORM_SUCCEEDED,
        payload: {
          message: response.data.message,
        },
      });
    } else if (response.data.status === 'validation_failed') {
      dispatch({
        type: SUBMIT_FORM_FAILED,
        payload: {
          message: response.data.message,
          errors: response.data.invalid_fields,
        },
      });
    }
  } catch (e) {
    console.error(e);
    dispatch({
      type: SUBMIT_FORM_FAILED,
    });
  }
};
