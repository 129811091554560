import React from 'react';
import { Formik, Field, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import NextScreenButton from '@c/Navigation/NextScreenButton';

// Actions
import { submitFormAsync } from '@s/store/submit-form/actions';

import s from './styles.module.scss';

const SubmitForm = ({ data }) => {
  const dispatch = useDispatch();
  const submitForm = useSelector((state) => state.submitForm).toJS();
  const { screenNext } = useSelector((state) => state.navigation).toJS();
  const { height, title, placeholder, button_label } = data;

  const startData = useSelector((state) => state.startData).toJS();
  const {
    startData: { acf },
  } = startData;

  const { contact_form_required } = acf;

  const style = {
    height: height + '%',
  };

  React.useEffect(() => {
    if (submitForm.successful) {
      document.getElementById('ButtonNext').click();
    }
  }, [submitForm]);

  const validate = (values, props) => {
    const errors = {};

    if (!values.message) {
      errors.message = contact_form_required;
    }

    return errors;
  };

  return (
    <div style={style} className={s['submit-form']}>
      <div className='app-container'>
        {/* {submitForm.message} */}
        <div style={style} className={s['submit-form__form']}>
          <div className={s['submit-form__title']}>{title}</div>
          <Formik
            validate={validate}
            enableReinitialize={true}
            initialValues={{
              message: '',
            }}
            onSubmit={(values, { resetForm }) => {
              const form = new URLSearchParams();
              for (const field in values) {
                form.append(field, values[field]);
              }
              dispatch(submitFormAsync(form));
              resetForm();
            }}>
            {({ errors, touched }) => (
              <Form>
                {/* <label htmlFor='firstName'>First Name</label>
            <Field id='firstName' name='firstName' placeholder='Jane' /> */}
                {/* <label htmlFor='email'>Email</label>
            <Field id='email' name='email' placeholder='jane@acme.com' type='email' /> */}

                <Field
                  placeholder={placeholder}
                  as='textarea'
                  name='message'
                  id='message'
                  rows='10'
                  className={`${s['submit-form__textarea']} app-input ${
                    touched.message && errors.message ? 'app-input--error' : ''
                  }`}
                />
                {/* {touched.message && errors.message && <div>{errors.message}</div>} */}

                <div>
                  <button className={`${s['submit-form__button']} app-button`} type='submit'>
                    {button_label}
                  </button>
                  <NextScreenButton />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SubmitForm;
