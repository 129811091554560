/*global google*/
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { secondsToHms, metersToKilometers } from '@u/converters';

import { markerStart, markerEnd } from './markers';

import { saveGameResults, saveAllPoints, saveRightPoints } from '@s/store/app-data/actions';
import { showModalBeforeRedirect } from '@s/store/routing/actions';

import { getCookie } from '@u/cookie';

import s from './styles.module.scss';

localStorage.removeItem('currentMapIsFinish');

const Map = ({ googleMapsLoaded, destination, dataGame, id, points }) => {
  const dispatch = useDispatch();
  const [duration, setDuration] = React.useState('');
  const [distance, setDistance] = React.useState('');
  const appData = useSelector((state) => state.appData).toJS();
  const { step } = useSelector((state) => state.routing).toJS();
  const startData = useSelector((state) => state.startData).toJS();
  const {
    startData: { acf },
  } = startData;
  const { map_target_distance } = acf;

  let markerStartIcon = null;
  let markerEndIcon = null;
  let originData = [];

  let geoOptions = {
    maximumAge: 5 * 60 * 1000,
    timeout: 10 * 3000,
  };

  function geoSuccess(position) {
    const origin = [position.coords.latitude, position.coords.longitude];

    originData = [position.coords.latitude, position.coords.longitude];

    calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination);
  }

  function geoError(error) {
    let errorText = '';
    switch (error.code) {
      case 0:
        errorText = 'unknown error';
        break;
      case 1:
        errorText = 'permission denied';
        break;
      case 2:
        errorText = 'position unavailable (error response from location provider)';
        break;
      case 3:
        errorText = 'timed out';
        break;
    }

    alert('Error occurred. Error code: ' + errorText);
  }

  const directionsService = new google.maps.DirectionsService();
  const directionsRenderer = new google.maps.DirectionsRenderer({
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: '#00671F',
      strokeWeight: 5,
    },
  });

  function initMap() {
    const map = new google.maps.Map(document.getElementById('map'), {
      // zoom: 7,
      // center: { lat: 48.7343833, lng: 37.598227 },
      // styles: styles,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: false,
      fullscreenControl: false,
    });

    directionsRenderer.setMap(map);
    // calculateAndDisplayRoute(
    //   directionsService,
    //   directionsRenderer,
    //   [48.7358574, 37.5915612],
    //   destination
    // );
    window.map = map;
  }

  function calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination) {
    const appDataCookie = JSON.parse(getCookie('appData'));
    let geolocationGame;
    if (appDataCookie.gamesResults[id]) {
      geolocationGame = appDataCookie.gamesResults[id];
    } else if (localStorage.getItem('currentMapIsFinish') === String(id)) {
      geolocationGame = true;
    } else {
      geolocationGame = false;
    }

    const request = {
      origin: new google.maps.LatLng(origin[0], origin[1]),
      destination: new google.maps.LatLng(destination[0], destination[1]),
      travelMode: google.maps.TravelMode.WALKING,
    };

    directionsService.route(request, function (response, status) {
      if (status === 'OK') {
        // setTimeout(()=>{
        //   console.log(1);
        //   directionsRenderer.set('preserveViewport', true);
        // },500)

        directionsRenderer.setDirections(response);

        //For render in html
        setDuration(secondsToHms(response.routes[0].legs[0].duration.value));
        setDistance(metersToKilometers(response.routes[0].legs[0].distance.value));

        if (markerStartIcon !== null) {
          markerStartIcon.setMap(null);
        }
        let leg = response.routes[0].legs[0];
        markerStartIcon = markerStart(leg.start_location);

        if (markerEndIcon === null) {
          if (!(geolocationGame && geolocationGame.isRight)) {
            markerEndIcon = markerEnd(leg.end_location, 'yellow', step);
          } else {
            markerEndIcon = markerEnd(leg.end_location, 'green', step);
          }
        }

        if (
          !geolocationGame &&
          response.routes[0].legs[0].distance.value <= Number(map_target_distance)
        ) {
          if (!geolocationGame || !geolocationGame.isRight) {
            if (markerEndIcon !== null) {
              markerEndIcon.setMap(null);
            }
            markerEndIcon = markerEnd(leg.end_location, 'green', step);

            const answersObj = {
              [id]: {
                type: 'Geolocation',
                isRight: true,
                isAnswer: false,
                allPoints: points,
                rightPoints: points,
              },
            };

            localStorage.setItem('currentMapIsFinish', id);
            dispatch(saveGameResults(answersObj));
            dispatch(saveRightPoints({ gameId: id, points }));

            setTimeout(() => {
              dispatch(showModalBeforeRedirect(true));
            }, 1000);
            setTimeout(() => {
              dispatch(showModalBeforeRedirect(false));
            }, 3000);
          }
        }
      } else {
        window.alert('Directions request failed due to ' + status);
      }
    });
  }

  React.useEffect(() => {
    dispatch(saveAllPoints({ gameId: id, points }));
    initMap();

    if (navigator.geolocation) {
      console.log('Geolocation is supported!');
      // TODO: Watch Position
      const watchID = navigator.geolocation.watchPosition(geoSuccess, geoError, geoOptions);
      return () => {
        navigator.geolocation.clearWatch(watchID);
      };

      // TODO: Manual Position
      //navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions);
    } else {
      console.log('Geolocation is not supported for this Browser/OS version yet.');
    }
  }, []);

  // console.log(destination, originData);
  const stepRatio = 0.001;
  const handleClickUp = React.useCallback(() => {
    console.log('Up');

    let origin = [originData[0] + stepRatio, originData[1]];
    originData = origin;
    calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination);
  }, []);

  const handleClickLeft = React.useCallback(() => {
    console.log('Left');
    let origin = [originData[0], originData[1] - stepRatio];
    originData = origin;
    calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination);
  }, []);

  const handleClickRight = React.useCallback(() => {
    console.log('Right');
    let origin = [originData[0], originData[1] + stepRatio];
    originData = origin;
    calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination);
  }, []);

  const handleClickDown = React.useCallback(() => {
    console.log('Down');
    let origin = [originData[0] - stepRatio, originData[1]];
    originData = origin;
    calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination);
  }, []);

  return (
    <div className={s['map']}>
      <div className={s['map__banner']}>
        <div className={`${s['map__banner-duration']} h2`}>{duration}</div>
        <div className={s['map__banner-distance']}>{distance}</div>
      </div>
      <div className={s['map__container']}>
        <div id='map' className={s['map__render']}></div>

        {/* <div className={s['map__move-controls']}>
          <button onClick={handleClickUp} className={s['map__move-up']} type='button'>
            ⇑
          </button>
          <button onClick={handleClickDown} className={s['map__move-down']} type='button'>
            ⇓
          </button>
          <button onClick={handleClickLeft} className={s['map__move-left']} type='button'>
            ⇐
          </button>
          <button onClick={handleClickRight} className={s['map__move-right']} type='button'>
            ⇒
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Map;
