import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//Components
import NextScreenButton from './NextScreenButton';

// Actions
import {
  changeCurrentPage,
  changeCurrentScreen,
  changeCurrentStep,
  changeCurrentChapter,
} from '@s/store/routing/actions';
import { changeNavigation } from '@s/store/navigation/actions';
import { clearGame } from '@s/store/game/actions';
import { changeAppData } from '@s/store/app-data/actions';

// Instruments
import { currentDate } from '@u/getDate';

// Icons
import { ReactComponent as LeftArrowButton } from '@img/left-arrow.svg';
import { ReactComponent as RightArrowButton } from '@img/right-arrow.svg';
import { ReactComponent as HomeButton } from '@img/home.svg';
import LandscapeImage from '@img/landscape-vector.svg';

import s from './styles.module.scss';

const Navigation = () => {
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const cheat = queryParams.get('cheat');

  const dispatch = useDispatch();
  const navigation = useSelector((state) => state.navigation);
  const chapter = useSelector((state) => state.chapter).toJS();
  const game = useSelector((state) => state.game).toJS();
  const { step, page, screen, chapterID } = useSelector((state) => state.routing).toJS();
  const appData = useSelector((state) => state.appData).toJS();
  const chapters = useSelector((state) => state.chapters).toJS();
  useEffect(() => {
    switch (page) {
      case 'main-menu':
        dispatch(
          changeNavigation({
            prev: null,
            next: null,
            screenPrev: null,
            screenNext: null,
            home: null,
          })
        );
        break;

      case 'start':
        dispatch(
          changeNavigation({
            prev: 'main-menu',
            next: null,
            screenPrev: null,
            screenNext: null,
            home: null,
          })
        );
        break;

      case 'lets-go':
        dispatch(
          changeNavigation({
            prev: null,
            next: null,
            screenPrev: null,
            screenNext: null,
            home: null,
          })
        );
        break;

      case 'chapters':
        const passedChapters = chapters.filter((el) => {
          return appData.completedChapters.indexOf(el.id) !== -1;
        });

        let nextRoute = 'chapter';

        if (chapters.length === passedChapters.length) {
          // nextRoute = 'congratulations';
          if (!appData.completedDate) {
            const newAppData = {
              ...appData,
              completedDate: currentDate(),
            };

            dispatch(changeAppData(newAppData));
          }

          dispatch(changeCurrentPage('congratulations'));
        }

        dispatch(
          changeNavigation({
            prev: null,
            next: nextRoute,
            screenPrev: null,
            screenNext: null,
            home: 'main-menu',
          })
        );
        break;

      case 'chapter':
        dispatch(
          changeNavigation({
            prev: null,
            next: null,
            screenNext: null,
            screenPrev: null,
            home: null,
          })
        );
        break;

      case 'statistic':
        dispatch(
          changeNavigation({
            prev: null,
            next: null,
            screenNext: null,
            screenPrev: null,
            home: null,
          })
        );
        break;

      case 'step':
        // if(!chapter.acf){
        //   return false
        // }
        const steps = chapter.acf.steps;
        const { screens } = steps[step - 1];

        const screenNext = screen + 1 <= screens.length ? screen + 1 : 'finish';
        const screenPrev = screen - 1 > 0 ? screen - 1 : null;

        if (game.acf) {
          dispatch(clearGame());
        }

        dispatch(
          changeNavigation({
            prev: !screenPrev ? 'chapter' : null,
            next: !screenNext ? 'chapter' : null,
            screenPrev: screenPrev,
            screenNext: screenNext,
          })
        );
        break;

      default:
        dispatch(
          changeNavigation({
            prev: null,
            next: null,
            screenPrev: null,
            screenNext: null,
          })
        );
        break;
    }
  }, [page, screen]);

  const gamesForNextButton = ['quiz', 'geolocation', 'text_input'];
  // useEffect(() => {
  //   if(){}
  // }, [game]);

  const prev = navigation.get('prev');
  const next = navigation.get('next');
  const home = navigation.get('home');
  const screenPrev = navigation.get('screenPrev');
  const screenNext = navigation.get('screenNext');

  const handleOnClickPrev = () => dispatch(changeCurrentPage(prev));
  const handleOnClickNext = () => dispatch(changeCurrentPage(next));
  const handleOnClickHome = () => dispatch(changeCurrentPage(home));

  const handleOnClickScreenPrev = () => dispatch(changeCurrentScreen(screenPrev));
  const pagesWitoutLanscape = ['welcome', 'hello', 'you-years', 'you-years-sorry', 'agb'];

  return (
    <div className={s.navigation}>
      {!pagesWitoutLanscape.includes(page) && (
        <img alt='Landscape' src={LandscapeImage} className={s['navigation__landscape']} />
      )}
      {prev && page !== 'step' && (
        <LeftArrowButton
          onClick={handleOnClickPrev}
          className={`${s['navigation__arrow']} ${s['navigation__arrow--left']}`}
        />
      )}
      {home && <HomeButton onClick={handleOnClickHome} className={s['navigation__home']} />}
      {next && (
        <RightArrowButton
          id='NextButton'
          onClick={handleOnClickNext}
          className={`${s['navigation__arrow']} ${s['navigation__arrow--right']}`}
        />
      )}

      {/* {screenPrev && (
        <LeftArrowButton
          onClick={handleOnClickScreenPrev}
          className={`${s['navigation__arrow']} ${s['navigation__arrow--left']}`}
        />
      )} */}
      {screenNext && game.acf && gamesForNextButton.includes(game.acf.game_type) && (
        <NextScreenButton>
          <RightArrowButton
            id='NextButton'
            className={`${s['navigation__arrow']} ${s['navigation__arrow--right']}`}
          />
        </NextScreenButton>
      )}

      {cheat !== null && (
        <>
          {screenNext && (
            <NextScreenButton>
              <RightArrowButton
                id='NextButton'
                className={`${s['navigation__arrow']} ${s['navigation__arrow--right']}`}
              />
            </NextScreenButton>
          )}
        </>
      )}
    </div>
  );
};

export default Navigation;
