// Types
import {
  DISABLE_NAVIGATION_PANEL,
  ENABLE_NAVIGATION_PANEL,
  CHANGE_CURRENT_PAGE,
  CHANGE_CURRENT_CHAPTER,
  CHANGE_CURRENT_STEP,
  CHANGE_CURRENT_SCREEN,
  CLEAR_ROUTING,
  SHOW_MODAL_BEFORE_REDIRECT,
} from './types';

export const enableNavigationPanel = () => {
  return {
    type: ENABLE_NAVIGATION_PANEL,
  };
};

export const disableNavigationPanel = () => {
  return {
    type: DISABLE_NAVIGATION_PANEL,
  };
};

export const showModalBeforeRedirect = (payload) => {
  return {
    type: SHOW_MODAL_BEFORE_REDIRECT,
    payload,
  };
};

export const clearRouting = () => {
  return {
    type: CLEAR_ROUTING,
  };
};

export const changeCurrentPage = (page) => {
  return {
    type: CHANGE_CURRENT_PAGE,
    payload: page,
  };
};

export const changeCurrentChapter = (chapter) => {
  return {
    type: CHANGE_CURRENT_CHAPTER,
    payload: chapter,
  };
};

export const changeCurrentStep = (step) => {
  return {
    type: CHANGE_CURRENT_STEP,
    payload: step,
  };
};

export const changeCurrentScreen = (screen) => {
  return {
    type: CHANGE_CURRENT_SCREEN,
    payload: screen,
  };
};

// export const changeCurrentPage = (route) => (dispatch) => {
//   dispatch(clearPosition());

//   dispatch({
//     type: CHANGE_CURRENT_PAGE,
//     payload: route,
//   });
// };
