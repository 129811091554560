import { Map, fromJS } from 'immutable';

import {
  DISABLE_NAVIGATION_PANEL,
  ENABLE_NAVIGATION_PANEL,
  CHANGE_CURRENT_PAGE,
  CHANGE_CURRENT_CHAPTER,
  CHANGE_CURRENT_STEP,
  CHANGE_CURRENT_SCREEN,
  CLEAR_ROUTING,
  SHOW_MODAL_BEFORE_REDIRECT,
} from './types';

const initialState = Map({
  page: 'first-loading',
  chapterID: null,
  step: 1,
  screen: 1,
  enable_navigation_panel: false,
  is_show_modal_before_redirect: false,
});

export const routingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_CURRENT_PAGE:
      return state.set('page', payload);

    case CHANGE_CURRENT_CHAPTER:
      return state.set('chapterID', payload);

    case CHANGE_CURRENT_STEP:
      return state.set('step', payload);

    case CHANGE_CURRENT_SCREEN:
      return state.set('screen', payload);

    case ENABLE_NAVIGATION_PANEL:
      return state.set('enable_navigation_panel', true);

    case DISABLE_NAVIGATION_PANEL:
      return state.set('enable_navigation_panel', false);

    case SHOW_MODAL_BEFORE_REDIRECT:
      return state.set('is_show_modal_before_redirect', payload);

    case CLEAR_ROUTING:
      const clearState = { ...initialState.toJS() };
      delete clearState.enable_navigation_panel;
      delete clearState.page;

      return fromJS({ ...state.toJS(), ...clearState });

    default:
      return state;
  }
};
