import { fromJS, List } from 'immutable';

import { FILL_CHAPTERS } from './types';

const initialState = List();

export const chaptersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILL_CHAPTERS:
      return fromJS(action.payload);
    default:
      return state;
  }
};
