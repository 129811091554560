import React from 'react';

import s from './styles.module.scss';

import { ReactComponent as ZapfenImage } from '@img/Zapfen.svg';
import { ReactComponent as ZapfenImageSmall } from '@img/Zapfen2.svg';

const HeadingDescription = ({ data }) => {
  const { heading, description, height } = data;
  const style = {
    height: height + '%',
  };

  return (
    <div style={style} className={`${s['heading-description']} heading-description  app-container`}>
      <ZapfenImage />
      <ZapfenImageSmall className={`${s['heading-description__zapfen-small']} small-zapfen`} />

      <div className={s['heading-description__heading-wrapper']}>
        {heading && <h1 className={`${s['heading-description__heading']} h2`}>{heading}</h1>}
      </div>
      {description && (
        <div
          className={`${s['heading-description__description']}`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
};

export default HeadingDescription;
