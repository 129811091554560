// Types
import { FETCH_CHAPTERS_ASYNC, FILL_CHAPTERS } from './types';

// Instruments
import { api } from '@/rest';

export const fillChapters = (chapters) => {
  return {
    type: FILL_CHAPTERS,
    payload: chapters,
  };
};

export const fetchChaptersAsync = () => async (dispatch) => {

  dispatch({
    type: FETCH_CHAPTERS_ASYNC,
  });

  const {data} = await api.chapters.fetch();
  
  const reverseChapters = data.reverse();
  dispatch(fillChapters(reverseChapters));
};

