import React, { useEffect, useRef, useState } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/dist/plyr.css';

import { controls } from './controls';
// import HeadingDescription from '../HeadingDescription';

// Styles
import s from './styles.module.scss';

// Images
// import { ReactComponent as ZapfenImage } from '@img/ZapfenBig.svg';
import { ReactComponent as PlayIcon } from '@img/Play.svg';
import { ReactComponent as RefreshIcon } from '@img/Refresh.svg';

const VideoPlayer = ({ data }) => {
  const { video_file, video_poster } = data;
  const ref = useRef();
  const playButtonRef = useRef();
  // useEffect(() => console.log(ref.current.plyr))
  const videoSrc = {
    type: 'video',
    sources: [
      {
        // src: "yWtFb9LJs3o",
        // provider: "youtube"
        src: video_file ? video_file.url : '',
        // type: 'video/mp4',
      },
    ],
    poster: video_poster ? video_poster.sizes.medium_large : '',
  };

  const audioOptions = {
    controls,
    // controls: ['play', 'progress', 'current-time', 'duration', 'mute', 'volume', 'fullscreen'],
  };

  const handleClickPlay = (e) => {
    const playButton = playButtonRef.current;
    const stateButton = playButton.getAttribute('data-state');

    if (stateButton === 'play') {
      playButton.setAttribute('data-state', 'pause');
      ref.current.plyr.pause();
    }

    if (stateButton === 'pause') {
      playButton.setAttribute('data-state', 'play');
      ref.current.plyr.play();
    }

    if (stateButton === 'refresh') {
      playButton.setAttribute('data-state', 'play');
      ref.current.plyr.play();
    }
  };

  useEffect(() => {
    const player = ref.current.plyr;
    const playButton = playButtonRef.current;
    player.on('ended', (event) => {
      playButton.setAttribute('data-state', 'refresh');
    });
    player.on('pause', (event) => {
      playButton.setAttribute('data-state', 'pause');
    });
    player.on('play', (event) => {
      playButton.setAttribute('data-state', 'play');
    });
  }, []);

  const style = {
    height: data.height + '%',
  };

  return (
    <div className={s['video-player']} style={style}>
      {/* <ZapfenImage className={s['video-player__zapfen']} /> */}
      {/* <HeadingDescription colorHeading='#fff' heading={data.heading} /> */}
      <div className={s['video-player__player']}>
        <button
          ref={playButtonRef}
          data-state='pause'
          className={s['video-player__play-button']}
          onClick={handleClickPlay}
          type='button'>
          <span>
            <PlayIcon className={s['video-player__play-icon']} />
            <RefreshIcon className={s['video-player__refresh-icon']} />
          </span>
        </button>
        <Plyr ref={ref} source={videoSrc} options={audioOptions} />
      </div>
    </div>
  );
};

export default VideoPlayer;
