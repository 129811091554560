import React, { useEffect, useRef, useState } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/dist/plyr.css';

// Styles
import s from './styles.module.scss';

const AudioPlayer = ({ data }) => {
  const ref = useRef();
  const viz = useRef();

  const { audio_file } = data;

  const audioSrc = {
    type: 'audio',
    sources: [
      {
        src: audio_file ? audio_file.url : '',
      },
    ],
  };

  useEffect(() => {
    (function () {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      if (window.AudioContext) {
        window.audioContext = new window.AudioContext();
      }
      var fixAudioContext = function (e) {
        if (window.audioContext) {
          // Create empty buffer
          var buffer = window.audioContext.createBuffer(1, 1, 22050);
          var source = window.audioContext.createBufferSource();
          source.buffer = buffer;
          // Connect to output (speakers)
          source.connect(window.audioContext.destination);
          // Play sound
          if (source.start) {
            source.start(0);
          } else if (source.play) {
            source.play(0);
          } else if (source.noteOn) {
            source.noteOn(0);
          }
        }
        // Remove events
        document.removeEventListener('touchstart', fixAudioContext);
        document.removeEventListener('touchend', fixAudioContext);
      };
      // iOS 6-8
      document.addEventListener('touchstart', fixAudioContext);
      // iOS 9
      document.addEventListener('touchend', fixAudioContext);
    })();

    const player = ref.current.plyr;
    let audio, context, analyser, src, array, logo;
    logo = viz.current.style;
    audio = document.querySelector('audio');
    // audio.crossOrigin = "anonymous";
    let isPlay = false;

    function preparation() {
      var context = window.audioContext;
      analyser = context.createAnalyser();
      src = context.createMediaElementSource(audio);
      src.connect(analyser);
      analyser.connect(context.destination);
      loop();
    }

    function loop() {
      if (!audio.paused) {
        window.requestAnimationFrame(loop);
      }
      array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);

      logo.height = array[40] / 10 + 'vh';
      logo.width = array[40] / 10 + 'vh';
    }

    player.on('play', (event) => {
      if (!isPlay) {
        preparation();
        isPlay = true;
      } else {
        loop();
      }
    });
  }, []);

  const audioOptions = { controls: ['play', 'progress', 'current-time', 'duration'] };

  const style = {
    height: data.height + '%',
  };

  return (
    <div style={style} className={`${s['audio-player']}`}>
      {/* {heading && <div className={`${s['audio-player__heading']} h2 text--red`}>{heading}</div>} */}
      {/* <HeadingDescription colorHeading='#cb0c0f' heading={heading} /> */}
      <div className={`${s['audio-player__player']}  app-container`}>
        <Plyr ref={ref} source={audioSrc} options={audioOptions} />
        <div className={s['audio-player__viz']} ref={viz}></div>
      </div>
    </div>
  );
};

export default AudioPlayer;
