import { Map } from 'immutable';

import { ADD_QUIZ_ANIMATION } from './types';

const initialState = Map({
  quiz: false,
});

export const animations = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_QUIZ_ANIMATION:
      return state.set('quiz', payload);

    default:
      return state;
  }
};
