import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import GameStartLoader from '@c/GameStartLoader';
import MainLayout from '@l/MainLayout';

//Actions
import { changeCurrentPage } from '@s/store/routing/actions';

import s from './styles.module.scss';

const Start = () => {
  const dispatch = useDispatch();
  const [percentage, setPercentage] = React.useState(3);
  const [intervalId, setIntervalId] = React.useState();

  const FINISH_LABEL =
    '<tspan x="82" dy="-0.4em">Los</tspan> <tspan x="82" dy="1em">geht´s</tspan>';

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((percentage) => percentage - 1);
    }, 1000);

    setIntervalId(interval);

    return () => clearInterval(interval);
  }, []);

  if (percentage < 1) {
    clearInterval(intervalId);
    setPercentage(FINISH_LABEL);
  }

  useEffect(() => {
    if (percentage === FINISH_LABEL) {
      setTimeout(() => {
        dispatch(changeCurrentPage('chapters'));
      }, 1000);
    }
  }, [percentage]);

  return (
    <MainLayout>
      <div className={s['start-page']}>
        <GameStartLoader percentage={percentage} />
      </div>
    </MainLayout>
  );
};

export default Start;
