// Config
export const ROOT_URL =
  process.env.NODE_ENV === 'development' ? 'https://rothaus.cookiefy.com' : window.location.origin;
//export const ROOT_URL = 'https://zaepfleweg.rothaus.de/';
//export const ROOT_URL = window.location.origin;

export const locale = localStorage.getItem('local') === 'en' ? 'en/' : '';

export const API_URL = `${ROOT_URL}/backend/${locale}index.php/wp-json/wp/v2`;
export const API_URL_CONTACT_FORM_7 = `${ROOT_URL}/backend/index.php/wp-json/contact-form-7/v1/contact-forms`;
