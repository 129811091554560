import React from 'react';
import { useSelector } from 'react-redux';

import s from './styles.module.scss';
import ProcentsCircle from './ProcentsCircle';

const ResultsScreen = ({ data }) => {
  const appData = useSelector((state) => state.appData).toJS();
  const chapter = useSelector((state) => state.chapter).toJS();

  const { text, heading, height } = data;
  const style = {
    height: height + '%',
  };

  const allScreensChapter = [];
  chapter.acf.steps.forEach((step) => {
    step.screens.forEach((screen) => {
      screen.sections_builder.forEach((section) => {
        if (section.acf_fc_layout === 'game') {
          allScreensChapter.push(section.game[0]);
        }
      });
    });
  });

  let allPoints = 0;
  let rightPoints = 0;
  allScreensChapter.forEach((gameId) => {
    const game = appData.gamesResults[gameId];
    if (game) {
      allPoints += Number(game.allPoints);
      rightPoints += Number(game.rightPoints);
    } else {
      allPoints += 0;
      rightPoints += 0;
    }
  });

  const calcResults = Math.round((rightPoints / allPoints) * 100);

  const raplaceText = text.replace('{{RESULT}}', calcResults);

  return (
    <div style={style} className={s['results-screen']}>
      <div className='app-container'>
        {heading && (
          <h1
            className={`${s['results-screen__heading']} h1 text--red`}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        <ProcentsCircle percentage={calcResults} />
        {text && (
          <div
            className={`${s['results-screen__description']}`}
            dangerouslySetInnerHTML={{ __html: raplaceText }}
          />
        )}
      </div>
    </div>
  );
};

export default ResultsScreen;
