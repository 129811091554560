import React from 'react';
import { useDispatch } from 'react-redux';

import s from './styles.module.scss';

import { changeCurrentPage } from '@s/store/routing/actions';

const Settings = () => {
  const dispatch = useDispatch();
  // const startData = useSelector((state) => state.startData).toJS();
  // const {
  //   startData: { acf },
  // } = startData;

  const links = [
    {
      label: 'AGBS',
      clickFunc: () => dispatch(changeCurrentPage('simple-agbs')),
    },
    {
      label: 'Impressum',
      clickFunc: () => dispatch(changeCurrentPage('simple-impressum')),
    },
    {
      label: 'Datenschutz',
      clickFunc: () => dispatch(changeCurrentPage('simple-datenschutz')),
    },
  ];

  return (
    <div className={`${s['settings']}`}>
      <div className={`${s['settings__heading']}`}>Headline</div>
      <div className={`${s['settings__list']}`}>
        {links.map((el) => (
          <div key={el.label} className={`${s['settings__list-item']}`}>
            <span className={`${s['settings__list-label']}`}>{el.label}</span>
            <button
              onClick={el.clickFunc}
              type='button'
              className={`${s['settings__list-button']}`}>
              view
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;
