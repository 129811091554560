import React from 'react';

import s from './styles.module.scss';

const StatisticPanel = ({ title, label, value }) => {
  return (
    <div className={s['statistic-panel']}>
      {title && (
        <div className={`${s['statistic-panel__title']} h3`}>
          <span>{title}</span>
          <span>{value}%</span>
        </div>
      )}

      <div className={s['statistic-panel__value']}>
        <div className={s['statistic-panel__value-progress']} style={{ width: `${value}%` }}></div>
        <div className={s['statistic-panel__value-mask']}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      {label && <div className={s['statistic-panel__label']}>{label}</div>}
    </div>
  );
};

export default StatisticPanel;
