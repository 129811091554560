import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import WhiteLayout from '@l/WhiteLayout';
import TopModal from '@c/TopModal';

// Actions
import { changeCurrentPage } from '@s/store/routing/actions';
import { changeAppData } from '@s/store/app-data/actions';

const Agb = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const appData = useSelector((state) => state.appData).toJS();
  const [showModal, setShowModal] = React.useState(false);

  const {
    startData: { acf },
  } = startData;

  const {
    privacy_policy_text,
    privacy_policy_button_name_yes,
    privacy_policy_button_name_no,
    privacy_policy_modal_decline,
  } = acf;

  const handleOnClickButtonNo = () => {
    setShowModal(true);
  };

  const handleOnClickButtonNoModal = () => {
    dispatch(changeCurrentPage('agb'));
  };

  const handleClickCloseModal = () => {
    setShowModal(false);
  };

  const handleOnClickButtonYes = () => {
    const newAppData = {
      ...appData,
      is_agb: true,
    };
    dispatch(changeAppData(newAppData));
    dispatch(changeCurrentPage('main-menu'));
  };

  const handleClickBackButton = () => {
    dispatch(changeCurrentPage('agb'));
  };

  return (
    <WhiteLayout
      enableLandscape={true}
      description={privacy_policy_text}
      backBattonFunc={handleClickBackButton}
      buttons={
        <>
          {privacy_policy_button_name_no && (
            <button
              onClick={handleOnClickButtonNo}
              type='button'
              className='app-button app-button--outline'>
              {privacy_policy_button_name_no}
            </button>
          )}
          {privacy_policy_button_name_yes && (
            <button
              onClick={handleOnClickButtonYes}
              type='button'
              className='app-button app-button--yellow'>
              {privacy_policy_button_name_yes}
            </button>
          )}
        </>
      }>
      <TopModal
        isShowModal={showModal}
        closeModalFunc={handleClickCloseModal}
        title={privacy_policy_modal_decline.heading}
        description={privacy_policy_modal_decline.description}
        buttons={
          <>
            {privacy_policy_modal_decline.button_name_no && (
              <button
                onClick={handleOnClickButtonNoModal}
                type='button'
                className='app-button app-button--outline'>
                {privacy_policy_modal_decline.button_name_no}
              </button>
            )}
            {privacy_policy_modal_decline.button_name_yes && (
              <button onClick={handleClickCloseModal} type='button' className='app-button'>
                {privacy_policy_modal_decline.button_name_yes}
              </button>
            )}
          </>
        }
      />
    </WhiteLayout>
  );
};

export default Agb;
