import React from 'react';
import { useSelector } from 'react-redux';

// Components
import StepScreens from '@c/StepScreens';

const StepItem = () => {
  const chapter = useSelector((state) => state.chapter);
  const routing = useSelector((state) => state.routing);
  const { step, screen } = routing.toJS();
  const chapterData = chapter.toJS();

  if (chapterData.acf === undefined) {
    return false;
  }

  const steps = chapterData.acf.steps;
  const { screens } = steps[step - 1];
  if (screens[screen - 1]) {
    return (
      <StepScreens
        screen={screens[screen - 1].sections_builder}
        screen_styles={screens[screen - 1].screen_styles}
      />
    );
  }
  //  else {
  //   return <h1>Missing data</h1>;
  // }
};

export default StepItem;
