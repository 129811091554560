import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Instruments
import Plyr from 'plyr-react';

// Components
import ShareButton from '@c/ShareButton';

// Actions
import { changeCurrentPage } from '@s/store/routing/actions';

// Styles
import 'plyr-react/dist/plyr.css';
import s from './styles.module.scss';

const Welcome = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const {
    startData: { acf },
  } = startData;
  const { welcome_video, welcome_button_name } = acf;

  const videoOptions = { controls: ['play-large'] };

  const handleOnClickButton = () => dispatch(changeCurrentPage('hello'));

  const ref = useRef();
  const videoSrc = {
    type: 'video',
    sources: [
      {
        src: welcome_video ? welcome_video.url : '',
      },
    ],
  };
  return (
    <div className={s['welcome-page']}>
      {/* <ShareButton /> */}
      <div className={s['welcome-page__video']}>
        {welcome_video && <Plyr ref={ref} source={videoSrc} options={videoOptions} />}
      </div>
      {welcome_button_name && (
        <button
          onClick={() => handleOnClickButton()}
          className={`${s['welcome-page__button']} app-button app-button--outline`}>
          {welcome_button_name}
        </button>
      )}
    </div>
  );
};

export default Welcome;
