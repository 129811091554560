// Instruments
import axios from 'axios';
import { API_URL, API_URL_CONTACT_FORM_7 } from './config';

const axiosInstance = axios.create({
  baseURL: API_URL,
  // timeout: 1000,
  // headers: {'Authorization': 'AUTH_TOKEN'}
});

const debugMod = 0;
const chapterSlug = !debugMod ? 'chapters' : 'debug-chapters';

export const api = {
  chapters: {
    fetch() {
      return axiosInstance({
        url: `${API_URL}/${chapterSlug}?status=publish&order=asc&orderby=menu_order`,
        method: 'GET',
      });
    },

    fetchItem(chapter) {
      return axiosInstance({
        url: `${API_URL}/${chapterSlug}/${chapter}`,
        method: 'GET',
      });
    },
  },

  game: {
    fetch(id) {
      return axiosInstance({
        url: `${API_URL}/games/${id}`,
        method: 'GET',
      });
    },
  },

  startData: {
    fetch() {
      return axiosInstance({
        url: `${API_URL}/front-page`,
        method: 'GET',
      });
    },
  },

  submitForm: {
    fetch(data) {
      return axiosInstance({
        url: `${API_URL_CONTACT_FORM_7}/977/feedback`,
        method: 'POST',
        data,
      });
    },
  },
};
