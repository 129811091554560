import React from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/dist/plyr.css';
import moment from 'moment';

// import { controls } from './controls';

// Styles
import s from './styles.module.scss';

// Images
import { ReactComponent as MessageImage } from '@img/message.svg';
import { ReactComponent as PlayIcon } from '@img/Play.svg';
import { ReactComponent as RefreshIcon } from '@img/Refresh.svg';

const TalkingHead = ({ data }) => {
  // const [currentTime, setCurrentTime] = React.useState(0);
  const { video_file, messages } = data;
  const ref = React.useRef();
  const playButtonRef = React.useRef();

  const videoSrc = {
    type: 'video',
    sources: [
      {
        src: video_file ? video_file.url : '',
      },
    ],
  };

  React.useEffect(() => {
    const player = ref.current.plyr;
    const messages = document.querySelectorAll('[data-message-showed]');
    const playButton = playButtonRef.current;

    player.on('timeupdate', (event) => {
      const currentTime = parseInt(event.detail.plyr.currentTime);

      messages.forEach((message) => {
        const messgeTime = Number(message.getAttribute('data-time'));
        const isShowed = message.getAttribute('data-message-showed') === 'true' ? true : false;

        if (messgeTime <= currentTime && !isShowed) {
          messages.forEach((message) => {
            message.style.display = 'none';
          });
          message.style.display = 'block';
          message.setAttribute('data-message-showed', true);
        }
      });
    });

    player.on('ended', (event) => {
      playButton.setAttribute('data-state', 'refresh');
      messages.forEach((message) => {
        message.setAttribute('data-message-showed', false);
      });
    });
    player.on('pause', (event) => {
      playButton.setAttribute('data-state', 'pause');
    });
    player.on('play', (event) => {
      playButton.setAttribute('data-state', 'play');
    });

    return () => {};
  }, []);

  const handleClickPlay = (e) => {
    const playButton = playButtonRef.current;
    const stateButton = playButton.getAttribute('data-state');

    if (stateButton === 'play') {
      playButton.setAttribute('data-state', 'pause');
      ref.current.plyr.pause();
    }

    if (stateButton === 'pause') {
      playButton.setAttribute('data-state', 'play');
      ref.current.plyr.play();
    }

    if (stateButton === 'refresh') {
      playButton.setAttribute('data-state', 'play');
      ref.current.plyr.play();
    }
  };

  const audioOptions = {
    controls: [],
    autoplay: false,
  };

  const style = {
    height: data.height + '%',
  };

  return (
    <div className={s['talking-head']} style={style}>
      <div className='app-container'>
        <div className={s['talking-head__messages']}>
          <MessageImage />
          <div className={s['talking-head__messages-text']}>
            {messages.map((message, i) => {
              const time = moment(message.timestamp, 'mm:ss').format('s');

              return (
                <div
                  data-message-showed='false'
                  className={s['talking-head__message']}
                  data-time={time}
                  key={i}>
                  <div>{message.text}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={s['talking-head__player']}>
        <button
          ref={playButtonRef}
          data-state='pause'
          className={s['talking-head__play-button']}
          onClick={handleClickPlay}
          type='button'>
          <span>
            <PlayIcon className={s['talking-head__play-icon']} />
            <RefreshIcon className={s['talking-head__refresh-icon']} />
          </span>
        </button>
        <Plyr ref={ref} source={videoSrc} options={audioOptions} />
      </div>
    </div>
  );
};

export default TalkingHead;
