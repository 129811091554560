import React from 'react';

import s from './styles.module.scss';

import { ReactComponent as ZapfenImage } from '@img/ZapfenBig.svg';
import { ReactComponent as BackIcon } from '@img/Back.svg';

const WhiteLayout = ({
  children,
  heading,
  image,
  description,
  buttons,
  checkbox,
  link,
  enableLandscape,
  backBattonFunc,
}) => {
  return (
    <div className={s['white-layout']}>
      <ZapfenImage className={s['white-layout__zapfen']} />
      <div className={`${s['white-layout__content']} app-container`}>
        {backBattonFunc && (
          <button className={s['white-layout__back']} type='button' onClick={backBattonFunc}>
            <BackIcon />
            Zurück
          </button>
        )}
        {heading && <div className={s['white-layout__heading']}>{heading}</div>}
        {image && <div className={s['white-layout__image']}>{image}</div>}
        {description && (
          <div className={`${s['white-layout__description']}`}>
            <div className='scroll-box' dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        )}
        {link && <div className={s['white-layout__link']}>{link}</div>}
        {checkbox && <div className={s['white-layout__checkboxes']}>{checkbox}</div>}
        {buttons && <div className={s['white-layout__buttons']}>{buttons}</div>}
      </div>
      {children}
    </div>
  );
};

export default WhiteLayout;
