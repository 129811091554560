import { setCookie } from '@u/cookie';

// Types
import { CHANGE_APP_DATA_SESSION } from './types';

// export const changeAppDataSession = (data) => {
//   return {
//     type: CHANGE_APP_DATA_SESSION,
//     payload: data
//   };
// };


export const changeAppDataSession = (data) => async (dispatch) => {
 
  setCookie('appDataSession', JSON.stringify(data));

  dispatch({
    type: CHANGE_APP_DATA_SESSION,
    payload: data
  });

};

