import { Map, fromJS } from 'immutable';

import { CHANGE_APP_DATA_SESSION } from './types';

const initialState = Map({
  firstLoading: false
});

export const appDataSessionReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case CHANGE_APP_DATA_SESSION:
      return fromJS(payload);

    default:
      return state;
  }
};
