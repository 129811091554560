import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../Loading';
import Quiz from '../Quiz';
import Geolocation from '../Geolocation';
import TextInput from '../TextInput';

import CenterModal from '../CenterModal';
import { ReactComponent as ZapfenImage } from '@img/Zapfen2.svg';

import { fetchGameAsync } from '@s/store/game/actions';

import s from './styles.module.scss';

const GameWrapper = ({ children, modalTitle, modalDescription, isZapfen }) => {
  const { is_show_modal_before_redirect } = useSelector((state) => state.routing).toJS();

  return (
    <>
      <CenterModal isShowModal={is_show_modal_before_redirect}>
        {modalTitle && <h1 className={`${s['game-modal__title']} h2`}>{modalTitle}</h1>}
        {modalDescription && (
          <div className={`${s['game-modal__description']}`}>{modalDescription}</div>
        )}
        {isZapfen && (
          <div className={s['game-modal__image']}>
            <ZapfenImage />
          </div>
        )}
      </CenterModal>
      {children}
    </>
  );
};

const Game = ({ data }) => {
  const game = useSelector((state) => state.game);
  const dispatch = useDispatch();

  const id = data.game[0];

  const gameObj = game.toJS();

  useEffect(() => {
    dispatch(fetchGameAsync(id));
  }, [fetchGameAsync]);

  if (Object.keys(gameObj).length == 0) {
    return <Loading />;
  }

  switch (gameObj.acf.game_type) {
    case 'quiz':
      return (
        <GameWrapper
          modalTitle={gameObj.acf.success_popup_heading}
          modalDescription={gameObj.acf.success_popup_text}
          isZapfen={Number(gameObj.acf.points) > 0}>
          <Quiz id={gameObj.id} data={gameObj.acf.quiz} points={gameObj.acf.points} />
        </GameWrapper>
      );

    case 'geolocation':
      return (
        <GameWrapper
          modalTitle={gameObj.acf.success_popup_heading}
          modalDescription={gameObj.acf.success_popup_text}
          isZapfen={Number(gameObj.acf.points) > 0}>
          <Geolocation id={gameObj.id} data={gameObj.acf.geolocation} points={gameObj.acf.points} />
        </GameWrapper>
      );

    case 'text_input':
      return (
        <GameWrapper
          modalTitle={gameObj.acf.success_popup_heading}
          modalDescription={gameObj.acf.success_popup_text}
          isZapfen={Number(gameObj.acf.points) > 0}>
          <TextInput id={gameObj.id} data={gameObj.acf.text_input} points={gameObj.acf.points} />
        </GameWrapper>
      );

    default:
      return <h1>Not found game "{gameObj.acf.game_type}"!</h1>;
  }
};

export default Game;
