import { Map } from 'immutable';

import { CHANGE_NAVIGATION } from './types';

const initialState = Map({
  prev: null,
  next: null,
  home: null,
  screenPrev: null,
  screenNext: null,
});

export const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_NAVIGATION:
      return state.merge(state, action.payload);

    default:
      return state;
  }
};
