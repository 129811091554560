import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Navigation from '@c/Navigation';
import DesktopPlaceholder from '@c/DesktopPlaceholder';
import RotatePlaceholder from '@c/RotatePlaceholder';

//hooks
import useCurrentWidth from '@/hooks/useCurrentWidth';
import useScreenOrientation from '@/hooks/useScreenOrientation';

// Pages
import ErrorPage from '@p/ErrorPage';
import LetsGo from '@p/LetsGo';
import Chapters from '@p/Chapters';
import ChapterItem from '@p/ChapterItem';
import StepItem from '@p/StepItem';
import FirstLoading from '@p/FirstLoading';
import Hello from '@p/Hello';
import Welcome from '@p/Welcome';
import YouYears from '@p/YouYears';
import YouYearsSorry from '@p/YouYearsSorry';
import Agb from '@p/Agb';
import PrivacyPolicy from '@p/PrivacyPolicy';
import MainMenu from '@p/MainMenu';
import Start from '@p/Start';
import Congratulations from '@p/Congratulations';
import SimpleAgbs from '@p/Simples/Agbs';
import SimpleImpressum from '@p/Simples/Impressum';
import SimpleDatenschutz from '@p/Simples/Datenschutz';
import Statistic from '@p/Statistic';

// Actions
import { fetchStartDataAsync } from '@s/store/start-data/actions';
import { changeCurrentPage } from '@s/store/routing/actions';

const RenderPage = ({ page }) => {
  switch (page) {
    case 'first-loading':
      return <FirstLoading />;

    case 'welcome':
      return <Welcome />;

    case 'hello':
      return <Hello />;

    case 'you-years':
      return <YouYears />;

    case 'you-years-sorry':
      return <YouYearsSorry />;

    case 'agb':
      return <Agb />;

    case 'privacy-policy':
      return <PrivacyPolicy />;

    case 'main-menu':
      return <MainMenu />;

    case 'start':
      return <Start />;

    case 'lets-go':
      return <LetsGo />;

    case 'chapters':
      return <Chapters />;

    case 'chapter':
      return <ChapterItem />;

    case 'step':
      return <StepItem />;

    case 'congratulations':
      return <Congratulations />;

    case 'simple-agbs':
      return <SimpleAgbs />;

    case 'simple-impressum':
      return <SimpleImpressum />;

    case 'simple-datenschutz':
      return <SimpleDatenschutz />;

    case 'statistic':
      return <Statistic />;

    default:
      return <ErrorPage />;
  }
};

const App = () => {
  const dispatch = useDispatch();
  const { page, enable_navigation_panel } = useSelector((state) => state.routing).toJS();
  const { startData } = useSelector((state) => state.startData).toJS();
  let widthScreen = useCurrentWidth();
  let screenOrientation = useScreenOrientation();

  useEffect(() => {
    dispatch(fetchStartDataAsync());
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('routeState')) {
      const routeState = sessionStorage.getItem('routeState');
      dispatch(changeCurrentPage(routeState));
      sessionStorage.removeItem('routeState');
      // if (this.routeName === 'single-post') {
      //   this.$store.commit(
      //     'routing/setSinglePostData',
      //     routeState.singlePostData
      //   );
      // }
    }
  }, []);

  if (startData.acf === undefined) {
    return false;
  }

  if (widthScreen <= 1024) {
    if (screenOrientation === 'horizontal') {
      return <RotatePlaceholder message={startData.acf.rotate_message} />;
    }
    return (
      <>
        <div className='main-content'>
          <RenderPage page={page} />
        </div>
        {enable_navigation_panel && <Navigation />}
      </>
    );
  } else {
    return <DesktopPlaceholder message={startData.acf.desktop_message} />;
  }
};

export default App;
