// Types
import { FETCH_GAME_ASYNC, FILL_GAME, CLEAR_GAME } from './types';

// Instruments
import { api } from '@/rest';

export const clearGame = () => {
  return {
    type: CLEAR_GAME,
  };
};

export const fillGame = (game) => {
  return {
    type: FILL_GAME,
    payload: game,
  };
};

export const fetchGameAsync = (id) => async (dispatch) => {

  dispatch({
    type: FETCH_GAME_ASYNC,
  });

  const {data} = await api.game.fetch(id);

  dispatch(fillGame(data));
};