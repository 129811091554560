import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import MainLayout from '@l/MainLayout';
import StatisticPanel from '@c/StatisticPanel';

// Actions
import { changeCurrentPage } from '@s/store/routing/actions';

// Images
import { ReactComponent as ShareIcon } from '@img/share.svg';

import shareNavigator from '@u/shareNavigator';

import s from './styles.module.scss';

const Statistic = () => {
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData).toJS();
  const chapters = useSelector((state) => state.chapters).toJS();
  const startData = useSelector((state) => state.startData).toJS();
  const {
    startData: { acf },
  } = startData;

  const { button_to_the_menu, button_statistics, button_share, statistic_label_card } = acf;

  const handleClickLink = () => {
    dispatch(changeCurrentPage('main-menu'));
  };

  const goToCongratulationsPage = () => {
    dispatch(changeCurrentPage('congratulations'));
  };

  const chaptersData = [];

  chapters.forEach((chapter) => {
    const chapterAcf = chapter.acf;
    const chapterData = {
      title: chapter.title.rendered,
      games: [],
      gamesProgress: null,
    };

    chapterAcf.steps.forEach((step) => {
      step.screens.forEach((screen) => {
        screen.sections_builder.forEach((section) => {
          if (section.acf_fc_layout === 'game') {
            const gameID = section.game[0];
            if (appData.gamesResults[gameID]) {
              chapterData.games.push({
                id: [gameID],
                allPoints: appData.gamesResults[gameID].allPoints,
                rightPoints: appData.gamesResults[gameID].rightPoints,
              });
            }
          }
        });
      });
    });

    let allPoints = 0;
    let rightPoints = 0;
    chapterData.games.forEach((game) => {
      if (game) {
        allPoints += Number(game.allPoints);
        rightPoints += Number(game.rightPoints);
      } else {
        allPoints += 0;
        rightPoints += 0;
      }
    });

    const calcResults = Math.round((rightPoints / allPoints) * 100);
    chapterData.gamesProgress = calcResults;
    chapterData.rightPoints = rightPoints;

    chaptersData.push(chapterData);
  });

  return (
    <MainLayout>
      <div className={`${s['statistic']} app-container`}>
        <h1 className={`${s['statistic__heading']} h1 text--red`}>{button_statistics}</h1>
        <div className={s['statistic__cards']}>
          {chaptersData.length &&
            chaptersData.map(({ title, gamesProgress, games, rightPoints }, i) => {
              const raplaceLabel = statistic_label_card.replace('{{RESULT}}', rightPoints);

              if (games.length) {
                return (
                  <StatisticPanel
                    key={i}
                    title={title}
                    value={gamesProgress}
                    label={raplaceLabel}
                  />
                );
              }
            })}
        </div>

        <div className={s['statistic__buttons']}>
          <button onClick={shareNavigator} type='button' className='app-button app-button--outline'>
            <ShareIcon />
            {button_share}
          </button>

          <button type='button' className='app-button' onClick={handleClickLink}>
            {button_to_the_menu}
          </button>
        </div>

        {appData.completedDate && acf.button_for_congratulations_page && (
          <div className={s['statistic__button-bottom']} onClick={goToCongratulationsPage}>
            <button type='button' className='app-button app-button--full'>
              {acf.button_for_congratulations_page}
            </button>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Statistic;
