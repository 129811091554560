import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MainMenuLayout from '@l/MainMenuLayout';

// Actions
import { changeCurrentPage, clearRouting } from '@s/store/routing/actions';
import { clearAppData } from '@s/store/app-data/actions';

const Start = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const appData = useSelector((state) => state.appData).toJS();

  const {
    startData: { acf },
  } = startData;

  const { start_heading, start_button_1, start_button_2 } = acf;

  const buttons = [
    {
      text: start_button_1,
      clickFunc: () => {
        dispatch(clearRouting());
        dispatch(clearAppData());
        dispatch(changeCurrentPage('lets-go'));
      },
    },
  ];

  if (appData.currentChapterId !== null) {
    buttons.push({
      text: start_button_2,
      clickFunc: () => {
        dispatch(changeCurrentPage('chapters'));
      },
    });
  }

  // useEffect(() => {
  //   // dispatch(enableNavigationPanel());
  // }, []);

  return <MainMenuLayout heading={start_heading} buttons={buttons} start={true} />;
};

export default Start;
